<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import roleMenuForm from "./role-menu-form.vue";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  page: {
    title: "Konfigurasi Menu Role",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    roleMenuForm,
  },
  data() {
    return {
      title: "Konfigurasi Menu Role",
      items: [
        {
          text: "Master",
          href: "/",
        },
        {
          text: "Menu",
          href: "/master/menu",
        },
        {
          text: "Konfigurasi Menu Role",
          active: true,
        },
      ],
      // Catch Error Axios
      axiosCatchError: null,
      list_role: [],
      selected_role: [],
      show_form: false,
    };
  },
  mounted() {
    let self = this;
    Swal.fire({
      title: '<i class="fas fa-spinner fa-spin"></i>',
      text: "Loading...",
      showConfirmButton: false,
      allowOutsideClick: false,
    });

    var config_list_role = {
      method: "get",
      url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/role-active",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.access_token,
      },
    };
    axios(config_list_role)
      .then(function (response) {
        var response_data = response.data;
        var response_data_fix = response_data.data;
        if (response_data.meta.code == 200) {
          self.list_role = response.data.data.referensi;
          Swal.close();
        } else {
          Swal.close();
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response_data_fix.data.message,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  methods: {
    ubah_role() {
      let self = this;
      self.show_form = false;
      if (self.selected_role) {
        // config untuk re render component
        self.$nextTick(() => {
          self.show_form = true;
        });
      } else {
        self.show_form = false;
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-12">
                <div class="card-title">Silahkan Pilih Role</div>
              </div>
              <div class="col-lg-12">
                <v-select
                  class="p-2"
                  id="id_role"
                  label="role_name"
                  :options="list_role"
                  v-model="selected_role"
                  @option:selected="ubah_role()"
                  placeholder="Pilih Role..."
                ></v-select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <role-menu-form v-if="show_form" :roles="selected_role"></role-menu-form>
    </div>
  </Layout>
</template>
