<script>
export default {
  props: ["columns", "sortKey", "sortOrders"],
};
</script>

<template>
  <div class="col-md-12">
    <div class="table-responsive">
      <table class="table mb-0 table-bordered table-condensed table-hover">
        <thead class="bg-dark text-center text-white">
          <tr>
            <th
              v-for="column in columns"
              :key="column.name"
              @click="column.name != '-' ? $emit('sort', column.name) : ''"
              :style="
                column.name != '-'
                  ? 'width:' + column.width + ';' + 'cursor:pointer;'
                  : 'width:' + column.width + ';'
              "
            >
              {{ column.label }}
              <i
                :class="
                  column.name != '-'
                    ? sortKey === column.name
                      ? sortOrders[column.name] > 0
                        ? 'bx bx-sort-up'
                        : 'bx bx-sort-down'
                      : 'bx bx-move-vertical'
                    : ''
                "
              ></i>
            </th>
          </tr>
        </thead>
        <slot></slot>
      </table>
    </div>
  </div>
</template>
