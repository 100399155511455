<script>
// import axios from "axios";
import { authComputed } from "@/state/helpers";
import Swal from "sweetalert2";

/**
 * Nav-bar Component
 */
export default {
  data() {
    return {
      navbarUser: {
        displayName: localStorage.getItem("session_name"),
        pathPhoto: localStorage.getItem("session_path_photo"),
        pathPhotoFull:
          process.env.VUE_APP_BACKEND_URL +
          localStorage.getItem("session_path_photo"),
      },
      roleData: JSON.parse(localStorage.getItem("session_role")),
      roleActive: localStorage.getItem("session_role_active_name"),
      sessionData: JSON.parse(localStorage.getItem("session")),
      configuration: JSON.parse(localStorage.getItem("configuration")),
    };
  },
  mounted() {},
  methods: {
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    ubahRole(key) {
      let self = this;
      let data_session = self.sessionData;

      localStorage.session_role_active_id = data_session.role[key].id_role;

      localStorage.session_role_active_name =
        data_session.menu_access[key].role_name;

      localStorage.session_role_active_index = key;

      localStorage.session_menu_access = JSON.stringify(
        data_session.menu_access[key].role_menu
      );

      let timerInterval;
      Swal.fire({
        icon: "success",
        title: "Berhasil",
        text: "Role Berhasil di ubah.",
        timer: 2000,
        timerProgressBar: true,
        showCancelButton: false,
        showConfirmButton: false,
        didOpen: () => {
          timerInterval = setInterval(() => {
            const content = Swal.getContent();
            if (content) {
              const b = content.querySelector("b");
              if (b) {
                b.textContent = Swal.getTimerLeft();
              }
            }
          }, 100);
        },
        willClose: () => {
          clearInterval(timerInterval);
        },
      }).then((result) => {
        /* Read more about handling dismissals below */
        if (result.dismiss === Swal.DismissReason.timer) {
          location.reload();
        }
      });
    },
  },
  computed: {
    ...authComputed,
  },
};
</script>

<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <router-link to="/" class="logo logo-dark">
            <span class="logo-sm">
              <img :src="configuration.LOGO_SMALL" height="22" />
            </span>
            <span class="logo-lg">
              <img :src="configuration.LOGO_FULL_DARK" height="22" />
            </span>
          </router-link>

          <router-link to="/" class="logo logo-light">
            <span class="logo-sm">
              <img :src="configuration.LOGO_SMALL" height="22" />
            </span>
            <span class="logo-lg">
              <img :src="configuration.LOGO_FULL_LIGHT" height="30" />
            </span>
          </router-link>
        </div>

        <button
          id="vertical-menu-btn"
          type="button"
          class="btn btn-sm px-3 font-size-16 header-item"
          @click="toggleMenu"
        >
          <i class="fa fa-fw fa-bars"></i>
        </button>
      </div>

      <div class="d-flex">
        <b-dropdown
          class="d-none d-lg-inline-block noti-icon"
          menu-class="dropdown-menu-lg dropdown-menu-end"
          right
          toggle-class="header-item"
          variant="black"
        >
          <template v-slot:button-content>
            <i class="bx bx-user"></i>
          </template>

          <div class="px-lg-2">
            <div class="row no-gutters">
              <div
                class="col"
                v-for="(row_data, key_data) in roleData"
                :key="key_data"
              >
                <a
                  v-if="row_data.role.role_name == roleActive"
                  style="color: black"
                  class="dropdown-icon-item"
                  href="javascript: void(0);"
                  v-on:click="ubahRole(key_data)"
                >
                  <img
                    src="@/assets/images/brands/user.png"
                    v-bind:alt="row_data.role.role_name"
                  />
                  <span>{{ row_data.role.role_name }}</span>
                </a>
                <a
                  v-else
                  class="dropdown-icon-item"
                  href="javascript: void(0);"
                  v-on:click="ubahRole(key_data)"
                >
                  <img
                    src="@/assets/images/brands/user.png"
                    v-bind:alt="row_data.role.role_name"
                  />
                  <span>{{ row_data.role.role_name }}</span>
                </a>
              </div>
            </div>
          </div>
        </b-dropdown>

        <div class="dropdown d-none d-lg-inline-block ms-1">
          <button
            type="button"
            class="btn header-item noti-icon"
            @click="initFullScreen"
          >
            <i class="bx bx-fullscreen"></i>
          </button>
        </div>

        <b-dropdown
          right
          variant="black"
          toggle-class="header-item"
          menu-class="dropdown-menu-end"
        >
          <template v-slot:button-content>
            <img
              v-if="navbarUser.pathPhoto != null"
              class="rounded-circle header-profile-user"
              v-bind:src="navbarUser.pathPhotoFull"
              alt="Header Avatar"
            />
            <img
              v-else
              class="rounded-circle header-profile-user"
              src="@/assets/images/users/avatar-9.png"
              alt="Header Avatar"
            />
            <span class="d-none d-xl-inline-block ms-1">
              <div v-if="navbarUser">{{ navbarUser.displayName }}&nbsp;</div>
              <div v-else>Smartsoft Studio</div>
            </span>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </template>
          <!-- item-->
          <b-dropdown-item>
            <router-link to="/profile" v-slot="{ navigate }" custom>
              <span @click="navigate" @keypress.enter="navigate">
                <i class="bx bx-user font-size-16 align-middle me-1"></i>
                {{ $t("navbar.dropdown.henry.list.profile") }}
              </span>
            </router-link>
          </b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <router-link to="/logout" class="dropdown-item text-danger">
            <i
              class="bx bx-power-off font-size-16 align-middle me-1 text-danger"
            ></i>
            {{ $t("navbar.dropdown.henry.list.logout") }}
          </router-link>
        </b-dropdown>
      </div>
    </div>
  </header>
</template>
