<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  page: {
    title: "Konfigurasi",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Konfigurasi",
      items: [
        {
          text: "Master",
          href: "/",
        },
        {
          text: "Konfigurasi",
          active: true,
        },
      ],
      // variable untuk cek akses menu - mohon jangan dihapus, ini wajib.
      namePathUrl: this.$route.name,
      sessionRoleId: localStorage.session_role_active_id,

      // Catch Error Axios
      axiosCatchError: null,
      listConfigurasi: [],
      valueConfigurasi: [],
    };
  },
  mounted() {
    let self = this;
    Swal.fire({
      title: '<i class="fas fa-spinner fa-spin"></i>',
      text: "Loading...",
      showConfirmButton: false,
      allowOutsideClick: false,
    });

    // load data konfigurasi
    var config_konfigurasi = {
      method: "get",
      url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/konfigurasi",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.access_token,
      },
    };
    axios(config_konfigurasi)
      .then(function (response) {
        var response_data = response.data;
        var response_data_fix = response_data.data[0];
        if (response_data.meta.code == 200) {
          self.listConfigurasi = response_data_fix.data_config;
          self.valueConfigurasi = response_data_fix.data_config;
          Swal.close();
        } else {
          Swal.close();
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response_data_fix.data.message,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  methods: {
    // Method dari masing masing fungsi di dalam file ini
    StoreData() {
      let self = this;
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });

      var config_store_data = {
        method: "post",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/konfigurasi",
        data: {
          value_config: JSON.stringify(self.valueConfigurasi),
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config_store_data)
        .then(function (response) {
          console.log(response);
          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: "Data Konfigurasi berhasil di perbarui.",
            timer: 2000,
            timerProgressBar: true,
            showCancelButton: false,
            showConfirmButton: false,
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              Swal.close();
              // self.$router.push({ name: "all-role" });
            }
          });
        })
        .catch(function (error) {
          self.axiosCatchError = error.response.data.data;
          Swal.close();
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <b-form class="p-2" @submit.prevent="StoreData">
            <div class="card-header">
              <div class="row">
                <div class="col-md-12">
                  <div class="card-title">Formulir Konfigurasi</div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div v-if="axiosCatchError">
                <div
                  class="alert alert-danger"
                  v-if="
                    typeof axiosCatchError === 'string' ||
                    axiosCatchError instanceof String
                  "
                >
                  {{ axiosCatchError }}
                </div>
                <div class="alert alert-danger" role="alert" v-else>
                  <div v-for="(errorArray, idx) in axiosCatchError" :key="idx">
                    <div v-for="(allErrors, idx) in errorArray" :key="idx">
                      <span class="text-danger">{{ allErrors[0] }} </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div
                  v-for="(row_data, key_data) in listConfigurasi"
                  :key="key_data"
                >
                  <div
                    v-if="
                      row_data.slug == 'ONE_TIME_LOGIN' ||
                      row_data.slug == 'CAPTCHA'
                    "
                  >
                    <div class="col-lg-12">
                      <div class="form-group mt-2">
                        <label>{{ row_data.slug }}</label>
                        <select
                          class="form-select"
                          v-model="valueConfigurasi[key_data].value"
                        >
                          <option value="Ya">Ya</option>
                          <option value="Tidak">Tidak</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div class="col-lg-12">
                      <b-form-group
                        class="mb-3"
                        v-bind:label="row_data.slug"
                        v-bind:label-for="row_data.slug"
                      >
                        <b-form-input
                          v-bind:id="row_data.slug"
                          placeholder="Masukkan Data..."
                          type="text"
                          v-model="valueConfigurasi[key_data].value"
                          :value="row_data.value"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="col-md-6">&nbsp;</div>
                <div class="col-md-6">
                  <div class="text-end">
                    <b-button type="reset" variant="danger"
                      ><i class="fa fa-redo-alt"></i> Reset</b-button
                    >
                    &nbsp;
                    <b-button type="submit" variant="primary"
                      ><i class="fa fa-save"></i> Simpan</b-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </Layout>
</template>
