<script>
/**
 * Dashboard Component
 */
import Materi from "./materi/materi.vue";
import Petunjuk from "./petunjuk/petunjuk.vue";
import axios from "axios";
import Swal from "sweetalert2";
export default {
  props: [
    "id_simulasi",
    "id_simulasi_config",
    "jumlah_soal",
    "jumlah_petunjuk",
    "is_locked_materi",
  ],
  components: {
    Materi,
    Petunjuk,
  },
  data() {
    return {
      id_simulasi_get: this.$props.id_simulasi,
      data_simulasi: [],
      loading: true,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      let self = this;

      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });

      var config = {
        method: "get",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "master/simulasi/" +
          self.id_simulasi_get,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then(function (response) {
          Swal.close();
          var response_data = response.data.data;
          self.data_simulasi = response_data;
          self.loading = false;
        })
        .catch(function (error) {
          self.axiosCatchError = error.response.data.data;
          Swal.close();
        });
    },
    // Method dari masing masing fungsi di dalam file ini
  },
};
</script>
<template>
  <div class="row">
    <div class="col-md-12">
      <div class="table-responsive">
        <table class="table table-nowrap mb-0">
          <tbody v-if="loading">
            <tr>
              <td>Loading</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <th scope="row" style="width: 20%">Nama Simulasi</th>
              <td>{{ data_simulasi.simulasi_config.nama }}</td>
            </tr>
            <tr>
              <th scope="row">No Urut</th>
              <td>{{ data_simulasi?.urutan }}</td>
            </tr>
            <tr>
              <th scope="row" style="width: 20%">Deskripsi</th>
              <td>{{ data_simulasi?.simulasi_config.deskripsi }}</td>
            </tr>
            <tr>
              <th scope="row">Tipe File Materi</th>
              <td v-if="data_simulasi?.simulasi_tipe_materi">
                {{ data_simulasi?.simulasi_tipe_materi.tipe_file_materi }}
              </td>
              <td v-else>-</td>
            </tr>
            <tr>
              <th scope="row" style="width: 20%">Jumlah Petunjuk</th>
              <td>{{ data_simulasi?.simulasi_config.jumlah_petunjuk }}</td>
            </tr>
            <tr>
              <th scope="row">Jumlah Soal</th>
              <td>{{ data_simulasi?.simulasi_config.jumlah_soal }}</td>
            </tr>
            <tr>
              <th scope="row" style="width: 20%">Tipe Jawaban</th>
              <td>{{ data_simulasi?.simulasi_config.tipe_jawaban }}</td>
            </tr>
            <tr>
              <th scope="row">Jumlah Jawaban</th>
              <td>{{ data_simulasi?.simulasi_config.jumlah_jawaban }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <hr style="border: 2px solid rgb(0, 0, 0); border-radius: 5px" />
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <Petunjuk
        :id_simulasi="id_simulasi"
        :id_simulasi_config="id_simulasi_config"
        :jumlah_soal="jumlah_soal"
        :jumlah_petunjuk="jumlah_petunjuk"
        :is_locked_materi="is_locked_materi"
      />
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <Materi
        :id_simulasi="id_simulasi"
        :id_simulasi_config="id_simulasi_config"
        :jumlah_soal="jumlah_soal"
        :jumlah_petunjuk="jumlah_petunjuk"
        :is_locked_materi="is_locked_materi"
      />
    </div>
  </div>
</template>
