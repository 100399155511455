<script>
import Editor from "@tinymce/tinymce-vue";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import Multiselect from "@vueform/multiselect";
import axios from "axios";
import Swal from "sweetalert2";
// import Swal from "sweetalert2";
export default {
  props: ["simulasi_id"],
  components: {
    Multiselect,
    Editor,
  },
  data() {
    return {
      // variable Page
      option_tipe_file: ["PDF", "JPEG", "Rich Text", "PPT", "Excel", "Video"],
      option_tipe_file_materi: ["MEMO", "MAIL", "SMS", "WA", "VIDEO", "NEWS", "AUDIO"],
      url_backend_pdf_js: process.env.VUE_APP_BACKEND_URL + "/pdf.js/web/viewer.html?file=",
      url_backend_file: process.env.VUE_APP_BACKEND_URL,
      option_simulasi_config: [],
      form_data: {
        nama_simulasi: "",
        judul: "",
        deskripsi: "",
        link_yt: "",
        isPetunjuk: false,
        simulasiconfig: "",
        target_user: "",
        file: "",
        tipe_file: "",
        tipe_file_materi: "",
        rich_text: "",
        tipe: "",
      },
    };
  },
  mounted() {
    this.getDataTable();
  },
  methods: {
    StoreData() {
      let self = this;
      let formData = new FormData();
      formData.append("judul", self.form_data.judul);
      formData.append("deskripsi", self.form_data.deskripsi);
      formData.append("link_yt", self.form_data.link_yt);
      formData.append("isPetunjuk", self.form_data.isPetunjuk);
      formData.append("simulasiconfig", self.form_data.simulasiconfig);
      formData.append("target_user", self.form_data.target_user);
      formData.append("file", self.form_data.file); // Append the image file
      formData.append("tipe_file", self.form_data.tipe_file); // Append the image file
      formData.append("tipe_file_materi", self.form_data.tipe_file_materi); // Append the image file
      formData.append("rich_text", self.form_data.rich_text); // Append the image file

      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });

      var config_store_data = {
        method: "post",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/simulasi-files/update/" + this.simulasi_id,
        data: formData,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
          "content-type": "multipart/form-data",
        },
      };

      axios(config_store_data)
        .then(function (response) {
          console.log(response);
          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: "Anda akan diarahkan ke halaman master simulasi files segera",
            timer: 1000,
            timerProgressBar: true,
            showCancelButton: false,
            showConfirmButton: false,
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              self.$emit("item-added");
            }
          });
        })
        .catch(function (error) {
          self.axiosCatchError = error.response.data.data;
          Swal.close();
        });
    },
    onFileChange(e) {
      this.form_data.file = e.target.files[0];
      console.log(this.form_data.file);
    },
    getDataTable() {
      let self = this;
      var url = process.env.VUE_APP_BACKEND_URL_VERSION + "master/simulasi-files/" + this.simulasi_id;

      var config = {
        method: "get",
        url: url,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
          access: "all-role",
          role_id: localStorage.session_role_active_id,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          console.log(response_data);
          let response_data_fix = response_data.data.data;
          if (response_data.meta.code == 200) {
            self.form_data.nama_simulasi = response_data_fix.simulasi_config.nama;
            self.form_data.judul = response_data_fix.judul;
            self.form_data.target_user = response_data_fix.target_user;
            self.form_data.deskripsi = response_data_fix.deskripsi;
            self.form_data.tipe_file = response_data_fix.tipe;
            self.form_data.file = response_data_fix.file;
            self.form_data.tipe_file_materi = response_data_fix.tipe_file_materi;
            self.form_data.link_yt = response_data_fix.link_yt;
            self.form_data.rich_text = response_data_fix.rich_text;
            if (response_data_fix.isPetunjuk) {
              self.form_data.tipe = "Petunjuk";
            } else {
              self.form_data.tipe = "Materi";
            }
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
  },
};
</script>
<template>
  <b-form class="p-2" @submit.prevent="StoreData">
    <div class="row mt-2">
      <div class="col-md-12">
        <b-form-group class="mb-3" label="Simulasi Config" label-for="formrow-nama-role-input">
          <b-form-input id="formrow-nama-role-input" placeholder="Simulasi Config" type="text" v-model="form_data.nama_simulasi" :readonly="true"></b-form-input>
        </b-form-group>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-12">
        <b-form-group class="mb-3" label="Judul" label-for="formrow-nama-role-input">
          <b-form-input id="formrow-nama-role-input" placeholder="Judul" type="text" v-model="form_data.judul" required></b-form-input>
        </b-form-group>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <label>target User</label>
        <multiselect v-model="form_data.target_user" :options="['Asesi', 'Asesor', 'Roleplayer']" :close-on-select="true" placeholder="Pilih Target User" required></multiselect>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-sm-12">
        <label>Tipe</label>
        <b-form-input id="formrow-nama-role-input" placeholder="Judul" type="text" v-model="form_data.tipe" :readonly="true" required></b-form-input>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="mt-3">
          <label for="formFile" class="form-label">Deskripsi</label>
          <textarea v-model="form_data.deskripsi" class="form-control" name="textarea" rows="5" required></textarea>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <label>Tipe File</label>
        <multiselect v-model="form_data.tipe_file" :options="option_tipe_file" :close-on-select="true" placeholder="Pilih Tipe File" required></multiselect>
      </div>
    </div>

    <div class="row mt-2" v-if="form_data.tipe_file == 'JPEG' || form_data.tipe_file == 'PDF'">
      <div class="col-sm-12">
        <div class="">
          <label for="formFile" class="form-label">File</label>
          <input class="form-control" type="file" id="formFile" ref="inputFile" v-on:change="onFileChange($event)" />
          <div v-if="form_data.tipe_file == 'PDF'">
            <a v-if="form_data?.file" :href="url_backend_pdf_js + url_backend_file + form_data?.file" target="_blank" class="btn btn-primary btn-sm mt-2">
              <i class="fa fa-eye me-2"></i>Lihat File Sekarang
            </a>
          </div>
          <div v-else>
            <a v-if="form_data?.file" :href="url_backend_file + form_data?.file" target="_blank" class="btn btn-primary btn-sm mt-2"> <i class="fa fa-eye me-2"></i>Lihat File Sekarang </a>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-sm-12">
        <label>Tipe File Materi (Isi Jika Intray)</label>
        <multiselect v-model="form_data.tipe_file_materi" :options="option_tipe_file_materi" :close-on-select="true" placeholder="Pilih Tipe File Materi"></multiselect>
      </div>
    </div>
    <div class="row mt-2" v-if="form_data.tipe_file == 'Rich Text'">
      <div class="col-md-12">
        <b-form-group class="mb-3" label="Rich Text" label-for="formrow-nama-role-input">
          <editor
            api-key="rtwwhvxttwvfpujt6gk5r2tphanjg73g2zwuq7ax98f864g0"
            :init="{
              height: 300,
              menubar: true,
              plugins: ['table'],
              contextmenu_never_use_native: true,
              toolbar:
                'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
            }"
            v-model="form_data.rich_text"
          />
        </b-form-group>
      </div>
    </div>
    <div class="row mt-2" v-if="form_data.tipe_file == 'Video' || form_data.tipe_file == 'Excel' || form_data.tipe_file == 'PPT'">
      <div class="col-md-12">
        <b-form-group class="mb-3" label="Link" label-for="formrow-nama-role-input">
          <b-form-input id="formrow-nama-role-input" placeholder="Link" type="text" v-model="form_data.link_yt" required></b-form-input>
        </b-form-group>
      </div>
    </div>
    <hr />
    <div class="row mt-2">
      <div class="col-12 text-end">
        <button type="submit" class="btn btn-primary btn-md"><i class="fa fa-edit"></i> Simpan</button>
      </div>
    </div>
  </b-form>
</template>
