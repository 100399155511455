<script>
import Multiselect from "@vueform/multiselect";
import axios from "axios";
import Swal from "sweetalert2";
// import Swal from "sweetalert2";
export default {
  props: ["existing_simulasi_config_id", "existing_no_urut"],
  components: {
    Multiselect,
  },
  data() {
    return {
      // variable Page
      selected_tipe_materi: "",
      showSimulasiForm: false,
      option_simulasi_config: [],
      option_simulasi_config_all: [],
      option_simulasi_config_tipe: [],
      status: "not_accepted",
      form_data: {
        urutan: "",
        simulasi_config: "",
        materi_id: this.$route.params.id,
        // deksripsi: "",
        tipe_materi: "",
        simulasi_config_tipe: "asdsad",
        is_wawancara: "",
        selected_simulasi_tipe: [],
        selected_materi: [],
      },
      id_materi_selected: "",
      id_simulasi_config_selected: "",
      option_materi: [],

      selected_simulasi: {
        jumlah_soal: "",
        jumlah_petunjuk: "",
        jumlah_jawaban: "",
      },
    };
  },
  mounted() {
    this.getSimulasiConfig();
    this.getSimulasiConfigAll();
  },
  methods: {
    getSimulasiConfig(keyword) {
      let self = this;

      var config_get_jenis_asesmen = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/simulasi-config",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
        params: {
          keyword,
          filter_simulasi_config_exist_all: this.$props.existing_simulasi_config_id,
        },
      };

      axios(config_get_jenis_asesmen)
        .then(function (response) {
          self.option_simulasi_config = response.data.data.referensi;
        })
        .catch(function (error) {
          self.axiosCatchError = error.response.data.data;
        });
    },
    getSimulasiConfigAll(keyword) {
      let self = this;

      var config_get_jenis_asesmen = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/simulasi-config-all",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
        params: {
          keyword,
          filter_simulasi_config_exist_all: this.$props.existing_simulasi_config_id,
        },
      };

      axios(config_get_jenis_asesmen)
        .then(function (response) {
          self.option_simulasi_config_all = response.data.data.referensi;
        })
        .catch(function (error) {
          self.axiosCatchError = error.response.data.data;
        });
    },
    searchSimulasiConfig(data) {
      this.getSimulasiConfig(data);
    },
    searchSimulasiConfigAll(data) {
      this.getSimulasiConfigAll(data);
    },

    StoreData() {
      let self = this;
      var no_urut = this.$props.existing_no_urut;
      if (self.form_data.simulasi_config.jumlah_soal != "Multiple") {
        self.form_data.simulasi_config_tipe = null;
        self.form_data.tipe_materi = null;
      }
      if (self.status == "not_accepted") {
        self.form_data.is_wawancara = null;
      }
      if (no_urut.includes(parseInt(self.form_data.urutan))) {
        Swal.fire({
          icon: "error",
          title: "Oops",
          text: "No urut sudah dipakai, silahkan ambil no urut lainya",
          timer: 1000,
          timerProgressBar: true,
          showCancelButton: false,
          showConfirmButton: false,
        }).then((result) => {
          /* Read more about handling dismissals below */
          if (result.dismiss === Swal.DismissReason.timer) {
            Swal.close();
          }
        });
      } else {
        Swal.fire({
          title: '<i class="fas fa-spinner fa-spin"></i>',
          text: "Loading...",
          showConfirmButton: false,
        });

        var config_store_data = {
          method: "post",
          url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/simulasi",
          data: this.form_data,
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.access_token,
          },
        };

        axios(config_store_data)
          .then(function (response) {
            console.log(response);
            Swal.fire({
              icon: "success",
              title: "Berhasil",
              text: "Anda akan diarahkan ke halaman master simulasi  segera",
              timer: 1000,
              timerProgressBar: true,
              showCancelButton: false,
              showConfirmButton: false,
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                window.location.reload();
              }
            });
          })
          .catch(function (error) {
            self.axiosCatchError = error.response.data.data;
            Swal.close();
          });
      }
    },
    searchMateri(data) {
      this.getMateri(data);
    },
    onSelectMateri(data) {
      this.showSimulasiForm = true;
      this.id_materi_selected = data.mm_id;
      this.getSimulasiConfigTipe();
    },
    getMateri(keyword) {
      let self = this;

      var config_get_jenis_asesmen = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/materi",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
        params: {
          keyword,
        },
      };

      axios(config_get_jenis_asesmen)
        .then(function (response) {
          self.option_materi = response.data.data.referensi;
        })
        .catch(function (error) {
          self.axiosCatchError = error.response.data.data;
        });
    },
    onSelectSimulasi() {
      this.id_simulasi_config_selected = this.selected_simulasi.id_simulasi_config;
    },
    getSimulasiConfigTipe(keyword) {
      let self = this;

      var config_get_jenis_asesmen = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/simulasi-by-materi",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
        params: {
          keyword,
          id_materi: this.id_materi_selected,
        },
      };
      axios(config_get_jenis_asesmen)
        .then(function (response) {
          self.option_simulasi_config_tipe = response.data.data.referensi;
        })
        .catch(function (error) {
          self.axiosCatchError = error.response.data.data;
        });
    },
    searchSimulasi(data) {
      this.getSimulasiConfigTipe(data);
    },
    onSelecTipeMateri(data) {
      this.selected_tipe_materi = data;
      if (this.selected_tipe_materi == "Ambil Simulasi Lain") {
        this.getMateri();
      }
    },
  },
};
</script>
<template>
  <b-form class="p-2" @submit.prevent="StoreData">
    <div class="row">
      <div class="col-md-4">
        <b-form-group class="mb-3" label="Urutan" label-for="formrow-nama-role-input">
          <b-form-input id="formrow-nama-role-input" placeholder="Masukkan Urutan..." type="number" v-model="form_data.urutan"></b-form-input>
        </b-form-group>
      </div>
      <div class="col-sm-8">
        <label>Simulasi</label>
        <multiselect
          v-model="form_data.simulasi_config"
          :options="option_simulasi_config_all"
          :object="true"
          value-prop="simulasiconfig_id"
          :close-on-select="true"
          track-by="nama"
          :searchable="true"
          label="nama"
          placeholder="Pilih Simulasi"
          @search-change="searchSimulasiConfigAll($event)"
        >
          <template v-slot:singleLabel="{}">
            <div>{{ "asasdsa" }}</div>
          </template>
          <template v-slot:option="{ option }">
            {{ "#" + option.simulasiconfig_id }} - {{ option.nama }} -
            {{ option.sub_nama }}
          </template>
        </multiselect>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <label for="formFile" class="form-label">Deskripsi</label>
        <textarea v-model="form_data.simulasi_config.deskripsi" class="form-control" name="textarea" rows="5" readonly></textarea>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-md-3">
        <b-form-group class="mb-3" label="Jumlah Soal" label-for="formrow-nama-role-input">
          <b-form-input id="formrow-nama-role-input" type="text" v-model="form_data.simulasi_config.jumlah_soal" readonly></b-form-input>
        </b-form-group>
      </div>
      <div class="col-md-3">
        <b-form-group class="mb-3" label="Jumlah Petunjuk" label-for="formrow-nama-role-input">
          <b-form-input id="formrow-nama-role-input" type="text" v-model="form_data.simulasi_config.jumlah_petunjuk" readonly></b-form-input>
        </b-form-group>
      </div>
      <div class="col-md-3">
        <b-form-group class="mb-3" label="Jumlah Jawaban" label-for="formrow-nama-role-input">
          <b-form-input id="formrow-nama-role-input" type="text" v-model="form_data.simulasi_config.jumlah_jawaban" readonly></b-form-input>
        </b-form-group>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-sm-12">
        <div class="form-check-primary mb-3">
          <b-form-checkbox id="checkbox-1" v-model="status" name="checkbox-1" value="accepted" unchecked-value="not_accepted"> Acakan mengikuti simulasi lain </b-form-checkbox>
        </div>
      </div>
    </div>

    <div class="row" v-if="status == 'accepted'">
      <div class="col-sm-8">
        <label>Pilih Simulasi Config Lain</label>
        <multiselect
          v-model="form_data.is_wawancara"
          :options="option_simulasi_config"
          :object="true"
          value-prop="simulasiconfig_id"
          :close-on-select="true"
          track-by="nama"
          :searchable="true"
          label="nama"
          placeholder="Pilih Simulasi Lain"
          @search-change="searchSimulasiConfig($event)"
        ></multiselect>
      </div>
    </div>
    <div class="row mt-4" v-if="form_data.simulasi_config.jumlah_soal == 'Multiple'">
      <div class="col-sm-4">
        <label>Tipe Materi</label>
        <multiselect
          v-model="form_data.tipe_materi"
          @change="onSelecTipeMateri"
          :options="['Ambil File & Acak', 'Ambil File dan Pilih', 'Ambil Simulasi Lain']"
          placeholder="Pilih Tipe Materi"
        ></multiselect>
      </div>
      <div class="row mt-2" v-if="selected_tipe_materi == 'Ambil Simulasi Lain'">
        <div class="col-sm-4">
          <label>Materi</label>
          <multiselect
            v-model="form_data.selected_materi"
            :options="option_materi"
            :object="true"
            value-prop="mm_id"
            :close-on-select="true"
            track-by="mm_nama"
            :searchable="true"
            label="mm_nama"
            placeholder="Pilih Materi"
            @change="onSelectMateri"
          ></multiselect>
        </div>
        <div class="col-sm-4" v-if="id_materi_selected">
          <label>Simulasi</label>
          <multiselect
            v-model="form_data.selected_simulasi_tipe"
            :options="option_simulasi_config_tipe"
            :object="true"
            value-prop="id_simulasi"
            :close-on-select="true"
            track-by="nama_simulasi"
            :searchable="true"
            label="nama_simulasi"
            placeholder="Pilih Simulasi"
            @search-change="searchSimulasi($event)"
            @select="onSelectSimulasi"
          ></multiselect>
        </div>
      </div>
    </div>

    <hr />
    <div class="row mt-2">
      <div class="col-12 text-end">
        <button type="submit" class="btn btn-primary btn-md"><i class="fa fa-edit"></i> Simpan</button>
      </div>
    </div>
  </b-form>
</template>
